import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Card, Typography } from 'antd'

import { machineReboot, machineRevert, machineTurnOn, machineTurnOff, machineFileDownload, getMachineResources } from '../../../actions/' // machineTurnOn, machineTurnOff
import { openNotificationPopup, confirm, getCookie } from '../../../actions/helpers'
import { messageTypes } from '../../../actions/messages'

// import { StyledUnresolvedBadge, StyledResolvedBadge } from '../../../styledComponents/sections/machines/badges';
import StyledStatusDisplay from '../../../styledComponents/common/statusDisplay'
import StyledStatusDisplay2 from '../../../styledComponents/common/statusDisplay2'
import StyledPowerSwitch from '../../../styledComponents/common/powerSwitch'
import StyledPowerSwitch2 from '../../../styledComponents/common/powerSwitch2'
import StyledConsoleDropdown from '../../../styledComponents/sections/machines/consoleDropdown'

import StyledRevertButton from '../../../styledComponents/sections/machines/revertButton'
import StyledRebootButton from '../../../styledComponents/sections/machines/rebootButton'
import StyledCardBody from '../../../styledComponents/sections/machines/cardBody'

const FileDownload = require('js-file-download')
const { Text } = Typography

class MachinesCard extends Component {
  constructor (props) {
    super(props)

    this.state = {
      status: undefined,
      resources: []
    }
  }

  componentDidMount () {
    this._isMounted = true
    const { element } = this.props

    element.machine_hd_id && this.getMachineResources(element)
  }

  componentDidUpdate (prevProps, prevState) {
    const { element } = this.props

    // if status changed and setStatus function from parent is set, set status to parent
    if (prevState.status !== this.state.status && this.props.setStatus) {
      this.props.setStatus(this.state.status)
    }

    if (prevProps.element?._id !== element?._id && element) {
      this.getMachineResources(element)
    }
  }

  onHandleReboot = (id) => {
    const { language } = this.props
    confirm('machine_reboot_question', language, () => {
      const prevStatus = this.getStatus()

      // allow setting statuses for this vm
      this.props.setAllowStateStatus(id)

      this.setState({
        status: 'rebooting'
      })
      machineReboot(id, getCookie('_token')).then((json) => {
        if (json.status === 'ok') {
          if (this._isMounted) {
            this.setState({ status: 'poweredOn' })

            openNotificationPopup(
              messageTypes[language].success,
              messageTypes[language].machine_reboot_ok,
              'smile',
              json.response,
              language
            )
          }
        } else if (json.status === 'err') {
          if (this._isMounted) {
            this.setState({ status: prevStatus })
          }
          openNotificationPopup(
            messageTypes[language].oops,
            messageTypes[language].machine_reboot_err,
            'frown',
            json.response[language] || json.response,
            language
          )
        }
      })
    })
  }

  onHandleRevert = (id) => {
    const { language } = this.props
    confirm('machine_revert_question', language, () => {
      // allow setting statuses for this vm
      this.props.setAllowStateStatus(id)

      this.setState({ status: 'reverting' })
      machineRevert(id, getCookie('_token')).then((json) => {
        if (json.status === 'ok') {
          if (this._isMounted) {
            this.setState({ status: 'poweredOn' })
            this.props.refreshData && this.props.refreshData(id, 'poweredOn')

            openNotificationPopup(
              messageTypes[language].success,
              messageTypes[language].machine_revert_ok,
              'smile',
              json.response,
              language
            )
          }
        } else if (json.status === 'err') {
          openNotificationPopup(
            messageTypes[language].oops,
            messageTypes[language].machine_revert_err,
            'frown',
            json.response[language] || json.response,
            language
          )
        }
      })
    })
  }

  togglePowerSwitch = (retStatus, id) => {
    const { language } = this.props

    // allow setting statuses for this vm
    this.props.setAllowStateStatus(id)

    this.setState({
      status: retStatus
    })

    if (retStatus === 'turning_on') {
      machineTurnOn(id, getCookie('_token')).then((json) => {
      //machineTurnOn(this.props.element?._id, getCookie('_token')).then((json) => {
      // machineTurnOn((this.props.element?.resources.status !== 'poweredOff' && id) || this.props.element?._id, getCookie('_token')).then((json) => {
      // machineTurnOn(id, getCookie('_token')).then((json) => {
        if (json.status === 'ok') {
          if (this._isMounted) {

            // const machines = this.props.machines
            //
            // const machine = machines.find(x => String(x._id) === String(this.props.element._id))
            //
            // if (machine) {
            //   machine.revert = json.vm_info.revert
            //   machine.console = json.vm_info.console
            // }

            // this.props.setMachines(machines)
            this.props.getAllMachines(false, null, () => {
              this.setState({ status: 'poweredOn' })
              this.props.refreshData && this.props.refreshData(id, 'poweredOn')
              openNotificationPopup(
                messageTypes[language].success,
                messageTypes[language].machine_on_ok,
                'smile',
                json.response,
                language
              )
            })
          }
        } else if (json.status === 'err') {
          openNotificationPopup(
            messageTypes[language].oops,
            json.response[language] || json.response,
            'frown',
            null,
            null,
            15
          )
          this.setState({ status: 'poweredOff' })
        }
      })
    } else if (retStatus === 'turning_off') {
      machineTurnOff(id, getCookie('_token')).then((json) => {
        if (json.status === 'ok') {
          if (this._isMounted) {
            this.setState({ status: 'poweredOff' })
            this.props.refreshData && this.props.refreshData(id, 'poweredOff')

            openNotificationPopup(
              messageTypes[language].success,
              messageTypes[language].machine_off_ok,
              'smile',
              json.response,
              language
            )
          }
        } else if (json.status === 'err') {
          openNotificationPopup(
            messageTypes[language].oops,
            messageTypes[language].machine_off_err,
            'frown',
            json.response[language] || json.response,
            language
          )
        }
      })
    }
  }

  onHandlePowerSwitch = (id) => {
    const { language } = this.props
    const status = this.getStatus()
    let retStatus = ''

    status === 'poweredOn'
      ? retStatus = 'turning_off'
      : status === 'poweredOff'
        ? retStatus = 'turning_on'
        : status === 'err'
          ? retStatus = 'turning_on'
          : retStatus = 'turning_on'

    if (retStatus === 'turning_on') { this.togglePowerSwitch(retStatus, id) } else {
      confirm('machine_power_question', language, () => {
        this.togglePowerSwitch(retStatus, id)
      })
    }
  }

  componentWillUnmount () {
    this._isMounted = false
    this.props.clearAllowMachineStateStatus && this.props.clearAllowMachineStateStatus(this.props.element.id)
  }

  getStatus = () => {
    const { element, stackActionStatus, allowStateStatus } = this.props
    const stateStatus = this.state.status
    let status = ''
    const stackActionStatuses = ['turning_on', 'poweringon', 'suspending', 'turning_on_after_revert', 'turning_off', 'poweredOn', 'poweredOff', 'reverting', 'failed', 'waiting-for-revert', 'waiting-for-poweron', 'waiting-for-suspend']

    if (stackActionStatuses.indexOf(stackActionStatus) > -1 && element.revert) {
      // when making action on stack set status of whole stack
      status = stackActionStatus
    } else if (allowStateStatus[element.id] === undefined && (stackActionStatus === undefined || !element.revert)) {
      // if there is no action on whole stack and real statuses are not allowed for this vm, use real state
      status = this.props.status
    } else {
      // in other case use state which was set manually
      status = stateStatus
    }

    return status
  }

  downloadMachineFile (element, filename, language, file) {
    machineFileDownload(element.machine_hd_id, filename, language, getCookie('_token')).then((response) => {
      // download file if get was successfull
      if (response.status === 200) {
        FileDownload(response.data, file.label[language] + '.' + file.file_type)
      } else {
        openNotificationPopup(messageTypes[language].oops, messageTypes.universal.error, 'frown')
      }
    })
  }

  getMachineResources (element) {
    getMachineResources(element.machine_hd_id, getCookie('_token')).then((json) => {
      if (json.status === 'ok') {
        this.setState({ resources: json.response })
      } else if (json.status === 'err') {
        openNotificationPopup(messageTypes[this.props.language].oops, json.response[this.props.language], 'frown')
      }
    })
  }

  render () {
    const { language, element, insideMap, desc: overwriteDesc, title: overwriteTitle, stack, stackActionStatus } = this.props
    const { resources } = this.state

    const { id } = element // solved
    const title = (element.disabled === true &&
                    ((stack.demo === 'partial' && messageTypes[language].element_name_not_available_demo) || messageTypes[language].machine_name_not_available)) ||
                    (overwriteTitle || element.name[language] || messageTypes[language].machine_no_name)
    const desc = (element.disabled === true &&
                    ((stack.demo === 'partial' && messageTypes[language].element_desc_not_available_demo) || messageTypes[language].machine_desc_not_available)) ||
                    (overwriteDesc || (element.desc && element.desc[language]) || messageTypes[language].machine_no_desc)
    const status = !element.disabled && this.getStatus()

    const statusColor = (status === 'poweredOn' && 'green') || (['poweredOff', 'err', 'failed', 'suspended'].includes(status) && 'gray') || 'yellow'

    return (
      <Card
        className={'vm-' + statusColor}
        title={
          <div>
            {/* solved ? <StyledResolvedBadge /> : <StyledUnresolvedBadge/> */}
            <span className='header-title'>
              <Text
                ellipsis
                title={title}
              >
                {title}
              </Text>
            </span>
            {!element.disabled &&
              (
                <StyledStatusDisplay2
                  status={status}
                  language={language}
                />
              )}
          </div>
        }
        bordered={!insideMap}
        actions={(!element.disabled && [
          <StyledRevertButton
            id={id}
            revert={stack && stack.revert && element.revert && status === 'poweredOn'}
            key='revert'
            language={language}
            status={status}
            stack={stack}
            stackActionStatus={stackActionStatus}
            handleClick={this.onHandleRevert}
          />,
          <StyledRebootButton
            id={id}
            reboot={element.reboot}
            key='reboot'
            status={status}
            stack={stack}
            stackActionStatus={stackActionStatus}
            language={language}
            handleClick={this.onHandleReboot}
          />,
          <StyledPowerSwitch2
            id={id}
            power={element.power}
            key='power'
            status={status}
            language={language}
            stack={stack}
            stackActionStatus={stackActionStatus}
            handleSwitch={this.onHandlePowerSwitch}
          />
        ].concat(!(!element.console || status !== 'poweredOn' || stackActionStatus !== undefined) ? [<StyledConsoleDropdown
          id={id}
          console={element.console}
          stackActionStatus={stackActionStatus}
          key='console'
          language={language}
          status={status}
          onHandleClick={this.props.onHandleConsole}
        />] : [])) || []}
      >
        <StyledCardBody
          desc={desc}
          element={element}
          language={language}
          insideMap={insideMap}
          downloadMachineFile={this.downloadMachineFile}
          resources={resources}
        />
      </Card>
    )
  }
}

const mapStateToProps = state => ({
  language: state.hdStore.language,
  stackActionStatus: state.hdStore.stackActionStatus
})

const MachinesCardContainer = connect(
  mapStateToProps
)(MachinesCard)

export default MachinesCardContainer
